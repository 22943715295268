import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useSwipeable } from 'react-swipeable';

import CardCarouselVerMais from '../../Cards/CardCarouselVerMais';

import style from './carouselCardsDefault.module.scss';

const getOrder = (index, pos, numItems) => (index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos);

const getInitialState = (numItems) => ({
  pos: numItems - 1,
  sliding: false,
  dir: 'NEXT',
});

function reducer(state, action) {
  switch (action.type) {
    case 'PREV':
      return {
        ...state,
        dir: 'PREV',
        sliding: true,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1,
      };
    case 'NEXT':
      return {
        ...state,
        dir: 'NEXT',
        sliding: true,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1,
      };
    case 'stopSliding':
      return { ...state, sliding: false };
    default:
      return state;
  }
}

export default function Carousel({ children, seeMore }) {
  const numItems = 5;
  const [state, dispatch] = React.useReducer(reducer, getInitialState(numItems));

  const slide = (dir) => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: 'stopSliding' });
    }, 50);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slide('NEXT'),
    onSwipedRight: () => slide('PREV'),
    swipeDuration: 550,
    preventScrollOnSwipe: true,
    trackMouse: true,
    trackTouch: true,
  });

  return (
    <div
      className={style.section}
      {...handlers}>
      <div className={style.wrapper}>
        <div className={style.carouselContainer}>
          {[
            ...React.Children.map(children, (child, index) => (
              <div
                className={style.carouselSlot}
                style={{ order: getOrder(index, state.pos, numItems) }}>
                {child}
              </div>
            )),
            <div
              key={5}
              className={style.carouselSlot}
              style={{ order: getOrder(3, state.pos, numItems) }}>
              <CardCarouselVerMais url={seeMore} />
            </div>,
          ]}
        </div>
      </div>
      <span className={style.slideButtonContainer}>
        <button
          type='button'
          aria-label='Itens anteriores'
          onClick={() => slide('PREV')}>
          <IoIosArrowBack />
        </button>
        <button
          type='button'
          aria-label='Próximos itens'
          onClick={() => slide('NEXT')}>
          <IoIosArrowForward />
        </button>
      </span>
    </div>
  );
}
